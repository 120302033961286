import { useRoute, useRouter } from "vue-router";
import { stringify, parse } from "jsurl2";

const useUrlPagination = <MDefault>(
  searchKey: string,
  defaultValue: MDefault
) => {
  const router = useRouter();
  const route = useRoute();

  const jsUrlObjToStr = <T>(obj: T) => {
    return stringify(obj);
  };

  const jsURLUpdateStrategy = <T>(req: T) => {
    router.replace({
      query: {
        ...route.query,
        [searchKey]: jsUrlObjToStr(req),
      },
    });
  };

  const jsURLParseStrategy = () => {
    try {
      const encoded = route.query[searchKey] as string;
      const result = parse(encoded) as MDefault;

      if (!result) {
        return defaultValue;
      }
      if (Object.keys(result).length === 0) {
        return defaultValue;
      }
      return result as MDefault;
      //   return schema.parse(fromUrl);
    } catch {
      return defaultValue;
    }
  };

  const updateURL = <T>(req: T) => {
    return jsURLUpdateStrategy(req);
  };

  const getFromURL = () => {
    return jsURLParseStrategy();
  };

  const getQueryString = <T>(req: T) => {
    return jsUrlObjToStr(req);
  };

  return { updateURL, getFromURL, getQueryString };
};

export default useUrlPagination;
